export const Check = ({className}: {className?: string}) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		className={className}
		viewBox="0 0 16 16"
		fill="none"
	>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M5.83062 13.3695L0.753696 8.26176C0.569081 8.07715 0.569081 7.76946 0.753696 7.58484L1.43062 6.90792C1.61523 6.7233 1.92293 6.7233 2.10754 6.90792L5.92293 10.7541C6.046 10.8771 6.26139 10.8771 6.38446 10.7541L13.8614 3.21561C14.046 3.03099 14.3537 3.03099 14.5383 3.21561L15.2152 3.89253C15.3998 4.07715 15.3998 4.38484 15.2152 4.56946L6.50754 13.3695C6.32293 13.5848 6.01523 13.5848 5.83062 13.3695Z"
			fill="currentColor"
		/>
	</svg>
)

import {createFileRoute, Link} from '@tanstack/react-router'
import {produce} from 'immer'
import {useEffect, useState} from 'react'
import {MdCheckCircle, MdError, MdInfo, MdRestore, MdSettings} from 'react-icons/md'
import {useBotConfig, useProject, useUpdateProject} from '~/api.ts'
import {EducatorTabs} from '~/components/EducatorTabs.tsx'
import {PromptEnhancementRevisions} from '~/components/PromptEnhancementRevisions.tsx'
import {EducatorSiteDisabled} from '~/components/SiteDisabled.tsx'

export const Route = createFileRoute('/_educator-layout/course/$courseId/prompt-enhancements')({
	component: CustomInstructions,
})

function CustomInstructions() {
	const {courseId} = Route.useParams()
	const botConfig = useBotConfig()
	const {data: project} = useProject()
	const [promptEnhancement, setPromptEnhancement] = useState(project?.prompt_templates.default.prompt_ai_to_know ?? '')
	const [showRevisions, setShowRevisions] = useState(false)
	const {mutateAsync: updateProject, isPending: updateProjectIsPending} = useUpdateProject()
	const [showSuccess, setShowSuccess] = useState(false)

	useEffect(() => {
		setPromptEnhancement(project?.prompt_templates.SOCRATIC.prompt_ai_to_know ?? '')
	}, [project, setPromptEnhancement])

	async function update(newPrompt: string) {
		if (project == undefined) return
		const updatedProject = produce(project, (draft) => {
			draft.prompt_templates.SOCRATIC.prompt_ai_to_know = newPrompt.trim()
			draft.prompt_templates.default.prompt_ai_to_know = newPrompt.trim()
		})

		await updateProject(updatedProject)
		setShowSuccess(true)
		setTimeout(() => {
			setShowSuccess(false)
		}, 5000)
	}

	return (
		<>
			<div className="flex min-h-[32px] shrink-0 flex-wrap items-center justify-between gap-y-[12px]">
				<EducatorTabs />
				<div className="flex flex-wrap gap-[8px]">
					<button
						onClick={() => {
							setShowRevisions(true)
						}}
						className="flex h-[32px] min-w-min items-center gap-[10px] border border-uom-heritage-100 px-[12px] text-uom-heritage-100 hover:bg-uom-blue-light-25 active:bg-uom-grey-light-25"
					>
						<MdRestore className="size-[16px]" />
						<div className="text-[14px] font-[700]">Revisions</div>
					</button>
					<button
						onClick={() => {
							void update(promptEnhancement)
						}}
						disabled={!botConfig?.is_locked_by_current_user || updateProjectIsPending || botConfig.project_id == null}
						className="min-w-[212px] bg-uom-blue-light-100 px-[12px] py-[6px] text-center text-[14px] font-[700] text-uom-heritage-100 hover:bg-uom-blue-light-50 active:bg-uom-blue-light-75 disabled:cursor-not-allowed disabled:bg-uom-blue-dark-50 disabled:text-uom-blue-dark-100"
					>
						{updateProjectIsPending ? 'Updating...' : 'Update prompt enhancements'}
					</button>
				</div>
			</div>
			<div className="mt-[24px]">
				{botConfig?.is_locked_by_current_user && botConfig.project_id == null && (
					<div className="mb-[24px] flex min-w-min max-w-content items-center gap-[10px] bg-uom-blue-light-25 p-[12px] md:w-2/3">
						<div className="shrink-0 bg-uom-blue-light-50 p-[6px]">
							<MdInfo className="size-[20px]" />
						</div>
						<div className="text-uom-heritage-100">
							To update prompt enhancements, you will first need to create a data set within the{' '}
							<Link
								to="/course/$courseId/lmsResources"
								params={{courseId}}
								className="underline"
							>
								LMS resources tab
							</Link>
							.
						</div>
					</div>
				)}
				{botConfig?.is_locked_by_current_user && (
					<div className="flex min-w-min max-w-max items-center gap-[4px] rounded-[2px] bg-uom-red-light-25 px-[8px] py-[4px] text-uom-heritage-100">
						<MdSettings className="size-[16px]" /> Advanced settings
					</div>
				)}

				{!botConfig?.locked && !botConfig?.is_locked_by_current_user && (
					<div className="mb-[24px] flex min-w-min max-w-content items-center gap-[10px] bg-uom-blue-light-25 p-[12px] md:w-2/3">
						<div className="shrink-0 bg-uom-blue-light-50 p-[6px]">
							<MdInfo className="size-[20px]" />
						</div>
						<div className="text-uom-heritage-100">
							To update prompt enhancements, you must prevent simultaneous edits by other admin users by toggling the <span className="font-[600]">'lock for editing'</span> option in the{' '}
							<Link
								to="/course/$courseId/access"
								params={{courseId}}
								className="underline"
							>
								access tab
							</Link>
							.
						</div>
					</div>
				)}

				<h1 className="mt-[8px] font-lead text-[30px] font-[500] leading-[110%] text-uom-heritage-100">Prompt enhancements</h1>
				<p className="mt-[8px] min-h-[39px] max-w-content text-[18px] md:w-2/3">
					The learning assistant’s actions and responses are guided by the default prompt and can be further refined with prompt enhancements.
				</p>

				<EducatorSiteDisabled>
					<div className="max-w-content md:w-2/3">
						{showSuccess ? (
							<div className="mt-[24px] flex items-center gap-[10px] bg-uom-green-light-25 p-[12px] text-uom-heritage-100">
								<div className="flex size-[32px] shrink-0 items-center justify-center bg-uom-green-light-50">
									<MdCheckCircle className="size-[20px]" />
								</div>
								<div>Prompt enhancements updated successfully.</div>
							</div>
						) : (
							<div className="mt-[24px] flex min-w-min items-center gap-[10px] bg-uom-yellow-25 p-[12px]">
								<div className="shrink-0 bg-uom-yellow-50 p-[6px]">
									<MdError className="size-[20px]" />
								</div>
								<div className="text-uom-heritage-100">
									Adding custom prompt enhancements below will change how the AI responds to and interacts with students. Test the responses thoroughly before granting students access to ensure they
									meet your expectations.
								</div>
							</div>
						)}

						<textarea
							className="mt-[24px] h-[348px] w-full border border-uom-grey-dark-50 p-[24px] text-[18px] leading-[140%] disabled:pointer-events-none disabled:cursor-not-allowed disabled:opacity-30"
							value={promptEnhancement}
							disabled={!botConfig?.is_locked_by_current_user || botConfig.project_id == null}
							onChange={(e) => {
								setPromptEnhancement(e.target.value)
							}}
						/>

						<button
							onClick={() => {
								setPromptEnhancement('')
								if ((project?.prompt_templates.default.prompt_ai_to_know.trim().length ?? 0) > 0) {
									void update('')
								}
							}}
							disabled={!botConfig?.is_locked_by_current_user || updateProjectIsPending || promptEnhancement === '' || botConfig.project_id == null}
							className="ml-auto mt-[12px] flex h-[32px] min-w-min items-center gap-[10px] border border-uom-heritage-100 px-[12px] text-uom-heritage-100 enabled:hover:bg-uom-blue-light-25 enabled:active:bg-uom-grey-light-25 disabled:cursor-not-allowed disabled:border-uom-grey-dark-75 disabled:text-uom-grey-dark-75"
						>
							<MdRestore className="size-[16px]" />
							<div className="text-[14px] font-[700]">Reset to default</div>
						</button>
					</div>
				</EducatorSiteDisabled>
			</div>
			<PromptEnhancementRevisions
				open={showRevisions}
				setOpen={setShowRevisions}
			/>
		</>
	)
}

import {Button, Disclosure, DisclosureButton, DisclosurePanel} from '@headlessui/react'
import {clsx} from 'clsx'
import {useAtom} from 'jotai/index'
import {Fragment, useState} from 'react'
import {MdArrowDropDown, MdSortByAlpha} from 'react-icons/md'
import {useDataSources} from '~/api.ts'
import {ChevronDownIcon} from '~/assets/ChevronDownIcon.tsx'
import type {Module, ModuleItem} from '~/model.ts'
import {selectedResourcesAtom} from '~/state.ts'
import {convertBytesNumberToString} from '~/util.ts'

const headers = [
	{attribute: 'name', label: 'TITLE'},
	{attribute: 'id', label: 'ID'},
	{attribute: 'status', label: 'STATUS'},
	{attribute: 'size', label: 'SIZE'},
	{attribute: 'type', label: 'TYPE'},
	{attribute: 'comment', label: 'COMMENTS'},
] as const

export function ModuleRow({module}: {module: Module}) {
	const [sortBy, setSortBy] = useState<(typeof headers)[number]['attribute'] | null>(null)
	const [isSortAscending, setIsSortAscending] = useState(true)
	const [selectedResources, setSelectedResources] = useAtom(selectedResourcesAtom)
	const {dataSourcesById} = useDataSources()
	const sortedItems =
		sortBy == null
			? module.items
			: [...module.items].sort((a, b) => {
					const aValue = a[sortBy]
					const bValue = b[sortBy]
					if (sortBy === 'size') {
						// Sort size by number
						if (aValue == null || typeof aValue === 'string') return isSortAscending ? 1 : -1
						if (bValue == null || typeof bValue === 'string') return isSortAscending ? -1 : 1
						return (isSortAscending ? 1 : -1) * (aValue - bValue)
					} else {
						// Sort other columns alphabetically
						if (aValue === bValue) return 0
						return (isSortAscending ? 1 : -1) * String(aValue).localeCompare(String(bValue))
					}
				})

	function selectAllModule() {
		const next = {...selectedResources}
		module.items.forEach((item) => {
			next[item.id] = true
			item.children?.forEach((child) => {
				next[child.id] = true
			})
		})
		setSelectedResources(next)
	}

	function selectNoneModule() {
		const next = {...selectedResources}
		module.items.forEach((item) => {
			next[item.id] = false
			item.children?.forEach((child) => {
				next[child.id] = false
			})
		})
		setSelectedResources(next)
	}

	const moduleAreAllSelected = module.items.every((item) => selectedResources[item.id] && (item.children == null || item.children.every((child) => selectedResources[child.id])))

	const moduleAreSomeSelected = module.items.some((item) => selectedResources[item.id] || item.children?.some((child) => selectedResources[child.id]))

	const errorCount = module.items.reduce((acc, item) => {
		if (dataSourcesById?.[item.id]?.status === 'ERROR') {
			acc++
		}
		if (item.children != null) {
			acc += item.children.reduce((acc, child) => {
				if (dataSourcesById?.[child.id]?.status === 'ERROR') {
					acc++
				}
				return acc
			}, 0)
		}
		return acc
	}, 0)

	return (
		<div className="w-full max-w-full overflow-x-hidden">
			<Disclosure
				as="div"
				className="group flex w-full flex-col"
			>
				<div className="flex w-full items-center justify-between gap-x-[12px] border border-uom-grey-dark-25 px-[12px] py-[8px] group-data-[open]:bg-uom-grey-light-25">
					<div className="flex items-center gap-x-[12px]">
						<div className="size-[18px]">
							<input
								type="checkbox"
								id={module.id + 'checkbox'}
								className={clsx(!moduleAreAllSelected && 'dash', 'learning-assistant-checkbox mr-[8px]')}
								checked={moduleAreSomeSelected}
								onChange={() => {
									if (moduleAreSomeSelected) {
										selectNoneModule()
									} else {
										selectAllModule()
									}
								}}
							/>
							<label htmlFor={module.id + 'checkbox'}>
								<span className="sr-only">
									{moduleAreSomeSelected ? (moduleAreAllSelected ? 'All child items are selected' : 'Some but not all child items are selected') : 'No child items selected'}
								</span>
							</label>
						</div>
						<DisclosureButton className="text text-start text-[14px] font-[600] text-uom-heritage-100">{module.name}</DisclosureButton>
					</div>
					<div className="flex shrink-0 items-center gap-x-[48px]">
						<div className="flex items-center gap-[4px] text-[12px] font-[600] leading-[100%] text-uom-heritage-100">
							{errorCount > 0 && <div className="rounded-[2px] bg-uom-red-light-25 px-[10px] py-[4px]">{errorCount} Errors</div>}
							<div className="rounded-[2px] bg-uom-blue-light-25 px-[10px] py-[4px] group-data-[open]:bg-white">{module.numberOfChildPages} Pages</div>
							<div className="rounded-[2px] bg-uom-blue-light-25 px-[10px] py-[4px] group-data-[open]:bg-white">{module.numberOfChildFiles} Files</div>
						</div>
						<DisclosureButton className="group flex items-center justify-center gap-2 border border-uom-heritage-100 p-[3px] hover:bg-uom-blue-light-25">
							<ChevronDownIcon className="size-[24px] group-data-[open]:rotate-180" />
						</DisclosureButton>
					</div>
				</div>
				<DisclosurePanel>
					<div className="w-full overflow-x-auto border border-t-0 border-uom-grey-dark-25">
						<table className="w-full">
							<thead className="bg-uom-grey-light-25">
								<tr className="text-[12px]">
									<th className="flex items-center py-[5px] pl-[24px]">
										<input
											type="checkbox"
											id={module.id + 'checkbox'}
											className={clsx(!moduleAreAllSelected && 'dash', 'learning-assistant-checkbox mr-[8px]')}
											checked={moduleAreSomeSelected}
											onChange={() => {
												if (moduleAreSomeSelected) {
													selectNoneModule()
												} else {
													selectAllModule()
												}
											}}
										/>
										<label htmlFor={module.id + 'checkbox'}>
											<span className="sr-only">
												{moduleAreSomeSelected ? (moduleAreAllSelected ? 'All child items are selected' : 'Some but not all child items are selected') : 'No child items selected'}
											</span>
										</label>
									</th>
									{headers.map(({attribute, label}) => (
										<th
											key={attribute}
											className="pr-[12px]"
										>
											<div className="flex items-center justify-between gap-x-[8px] text-left">
												<div>{label}</div>
												<Button
													className="border border-transparent p-[4px] hover:bg-white focus:border-uom-heritage-100 focus:text-uom-heritage-100 focus:outline-none"
													onMouseDown={() => {
														if (sortBy === attribute) {
															setIsSortAscending(!isSortAscending)
														}
														setSortBy(attribute)
													}}
												>
													{sortBy === attribute ? <MdArrowDropDown className={clsx(isSortAscending && 'rotate-180', 'size-[16px]')} /> : <MdSortByAlpha className="size-[16px]" />}
												</Button>
											</div>
										</th>
									))}
								</tr>
							</thead>
							<tbody>
								{sortedItems.map((moduleItem) => (
									<Fragment key={moduleItem.id}>
										<ModuleItemRow item={moduleItem} />
										{moduleItem.children?.map((moduleItemChild) => (
											<ModuleItemRow
												key={moduleItemChild.id}
												item={moduleItemChild}
												isChild
											/>
										))}
									</Fragment>
								))}
							</tbody>
						</table>
					</div>
				</DisclosurePanel>
			</Disclosure>
		</div>
	)
}

function ModuleItemRow({item, isChild}: {item: ModuleItem; isChild?: boolean}) {
	const [selectedResources, setSelectedResources] = useAtom(selectedResourcesAtom)
	const {dataSourcesById} = useDataSources()

	return (
		<tr className="border-t border-uom-grey-dark-25 py-[5px] pl-[24px] pr-[12px]">
			<td className="">
				<div className={clsx(isChild ? '--pr-[12px] pl-[36px]' : 'pl-[24px] pr-[12px]', 'flex flex-col items-center py-[5px]')}>
					<input
						type="checkbox"
						id={item.id + 'checkbox'}
						className="learning-assistant-checkbox"
						checked={selectedResources[item.id] ?? false}
						onChange={(e) => {
							setSelectedResources({
								...selectedResources,
								[item.id]: e.target.checked,
							})
						}}
					/>
					<label htmlFor={item.id + 'checkbox'}>
						<span className="sr-only">select</span>
					</label>
				</div>
			</td>
			<td className="max-w-max">
				<div className={clsx(isChild ? 'w-[438px] pl-[12px]' : 'w-[450px]', 'overflow-hidden overflow-ellipsis whitespace-nowrap pr-[12px] text-uom-heritage-100')}>
					{isChild && '/'}
					<a
						href={item.url}
						target="_blank"
						rel="noreferrer"
						className="underline"
					>
						{isChild ? item.name.substring(item.name.indexOf('/') + 1) : item.name}
					</a>
				</div>
			</td>
			<td className={clsx(isChild && 'pl-[12px]')}>
				<div className="w-[300px] overflow-hidden overflow-ellipsis whitespace-nowrap pr-[12px]">{item.id}</div>
			</td>
			<td className={clsx(isChild && 'pl-[12px]')}>
				{dataSourcesById?.[item.id] == null ? (
					<div className="max-w-max rounded-[2px] bg-uom-grey-light-25 px-[10px] py-[4px] text-[12px] font-[600] leading-none text-uom-heritage-100">N/A</div>
				) : dataSourcesById[item.id]?.status === 'ERROR' ? (
					<div className="max-w-max rounded-[2px] bg-uom-red-light-25 px-[10px] py-[4px] text-[12px] font-[600] leading-none text-uom-heritage-100">ERROR</div>
				) : (
					<div className="max-w-max rounded-[2px] bg-uom-green-light-25 px-[10px] py-[4px] text-[12px] font-[600] uppercase leading-none text-uom-heritage-100">{dataSourcesById[item.id]?.status}</div>
				)}
			</td>
			<td className={clsx(isChild && 'pl-[12px]')}>
				<div className="max-w-max rounded-[2px] bg-uom-blue-light-25 px-[10px] py-[4px] text-[12px] font-[600] leading-none text-uom-heritage-100">{convertBytesNumberToString(item.size)}</div>
			</td>
			<td className={clsx(isChild && 'pl-[12px]')}>
				<div className="max-w-max rounded-[2px] bg-uom-blue-light-25 px-[10px] py-[4px] text-[12px] font-[600] leading-none text-uom-heritage-100">{item.type === 'page' ? 'PAGE' : 'FILE'}</div>
			</td>
			<td>
				<div className="whitespace-nowrap pr-[12px]">{dataSourcesById?.[item.id]?.comment}</div>
			</td>
		</tr>
	)
}

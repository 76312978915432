import {Menu, MenuButton, MenuItem, MenuItems} from '@headlessui/react'
import {useAtom} from 'jotai'
import {useState} from 'react'
import {HiEllipsisVertical} from 'react-icons/hi2'
import {useThread} from '~/api.ts'
import {Check} from '~/assets/Check.tsx'
import {Tooltip, TooltipContent, TooltipTrigger} from '~/components/Tooltip.tsx'
import {deleteThreadIdAtom, isShowMobileMenuAtom, renameThreadIdAtom} from '~/state.ts'
import {copyThreadToClipboard} from '~/util.ts'

interface Props {
	threadId?: string
}

export function ThreadMenu(props: Props) {
	const [, setRenameThreadId] = useAtom(renameThreadIdAtom)
	const [, setDeleteThreadId] = useAtom(deleteThreadIdAtom)
	const {data: thread} = useThread(props.threadId)
	const [showCopyCheck, setShowCopyCheck] = useState(false)
	const [, setShowMobileMenu] = useAtom(isShowMobileMenuAtom)

	function copyEntireThread(event: React.MouseEvent<HTMLButtonElement>) {
		event.preventDefault()
		if (!thread) return
		copyThreadToClipboard(thread)
		setShowCopyCheck(true)
		setTimeout(() => {
			setShowCopyCheck(false)
		}, 5000)
	}

	if (!thread) return null

	return (
		<Menu as="div">
			<Tooltip placement="bottom">
				<TooltipTrigger>
					<MenuButton className="flex size-[32px] items-center justify-center border border-uom-heritage-100 bg-white text-uom-heritage-100 hover:bg-uom-blue-light-25 active:bg-uom-grey-light-25">
						<HiEllipsisVertical className="size-[20px]" />
					</MenuButton>
				</TooltipTrigger>
				<TooltipContent className="z-[999] bg-uom-blue-dark-100 px-[8px] py-[4px] text-white">More</TooltipContent>
			</Tooltip>
			<MenuItems className="absolute right-0 top-[28px] z-10 mt-2 min-w-[240px] border border-uom-grey-light-50 bg-white text-[16px] md:top-[64px]">
				<MenuItem>
					<button
						className="flex w-full items-center justify-between px-4 py-2 text-left data-[active]:bg-uom-grey-light-25"
						onClick={copyEntireThread}
					>
						<div>Copy entire thread</div>
						{showCopyCheck && <Check className="size-[16px] text-uom-heritage-100" />}
					</button>
				</MenuItem>
				<MenuItem>
					<button
						className="block w-full px-4 py-2 text-left data-[active]:bg-uom-grey-light-25"
						onClick={() => {
							setShowMobileMenu(true)
							setRenameThreadId(thread.SK)
						}}
					>
						Rename thread
					</button>
				</MenuItem>
				<MenuItem>
					<button
						onClick={() => {
							setDeleteThreadId(thread.SK)
						}}
						className="block w-full px-4 py-2 text-left text-uom-red-light-100 data-[active]:bg-uom-grey-light-25"
					>
						Delete thread
					</button>
				</MenuItem>
			</MenuItems>
		</Menu>
	)
}

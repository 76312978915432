import {createFileRoute, Link} from '@tanstack/react-router'
import {clsx} from 'clsx'
import {useAtom} from 'jotai'
import {useEffect, useMemo} from 'react'
import {MdCheckCircle, MdCloudUpload, MdError, MdInfo} from 'react-icons/md'
import {queryClient, useBotConfig, useDataSet, useDataSources, useIndexModules, useModules, useUpdateDataSet} from '~/api.ts'
import {Spinner} from '~/assets/Spinner.tsx'
import {EducatorTabs} from '~/components/EducatorTabs.tsx'
import {ModuleRow} from '~/components/ModuleRow.tsx'
import {EducatorSiteDisabled} from '~/components/SiteDisabled.tsx'
import {isContinuePollingStatus, type ModuleItem} from '~/model.ts'
import {selectedResourcesAtom} from '~/state.ts'
import {convertBytesNumberToString, convertBytesStringToNumber} from '~/util.ts'

export const Route = createFileRoute('/_educator-layout/course/$courseId/lmsResources')({
	component: LmsResources,
})

const MAX_DATA_SET_SIZE = import.meta.env.VITE_MAX_DATA_SET_SIZE ?? ''
const MAX_DATA_SET_SIZE_BYTES = convertBytesStringToNumber(MAX_DATA_SET_SIZE)

function LmsResources() {
	const {courseId} = Route.useParams()
	const {data: modules} = useModules()
	const {indexModules, indexModulesIsPending} = useIndexModules()
	const botConfig = useBotConfig()
	const {mutateAsync: updateDataSet, isPending: updateDataSetIsPending} = useUpdateDataSet()
	const [selectedResources, setSelectedResources] = useAtom(selectedResourcesAtom)
	const {dataSet} = useDataSet()
	const {dataSources, dataSourcesHasErrors} = useDataSources()

	const itemsById = useMemo(
		() =>
			modules.reduce<Record<string, ModuleItem | undefined>>((acc, module) => {
				module.items.forEach((item) => {
					acc[item.id] = item
					item.children?.forEach((child) => {
						acc[child.id] = child
					})
				})
				return acc
			}, {}),
		[modules],
	)

	const selectedSize = useMemo(
		() =>
			Object.keys(selectedResources)
				.filter((key) => selectedResources[key])
				.reduce((acc, key) => {
					const item = itemsById[key]
					if (item != null) {
						acc += item.size
					}
					return acc
				}, 0),
		[selectedResources, itemsById],
	)
	const overSize = selectedSize > MAX_DATA_SET_SIZE_BYTES

	useEffect(() => {
		if (dataSources != null && Object.keys(selectedResources).length == 0) {
			const next: Record<string, boolean> = {}
			dataSources.forEach((dataSource) => {
				console.log(dataSource.status)
				if (dataSource.unique_tag != null && dataSource.status === 'READY') {
					next[dataSource.unique_tag] = true
				}
			})
			setSelectedResources(next)
		}
	}, [dataSources, setSelectedResources])

	const isUpdating = (botConfig != null && isContinuePollingStatus(botConfig.status)) || updateDataSetIsPending

	async function update() {
		const selected: ModuleItem[] = Object.keys(selectedResources)
			.filter((key) => selectedResources[key])
			.map((key) => {
				const itemToSend = itemsById[key]
				if (itemToSend != null) {
					itemToSend.children = undefined
				}
				return itemToSend
			})
			.filter((item) => item != null)

		await updateDataSet(selected)
		setSelectedResources({})
		void queryClient.invalidateQueries({queryKey: ['tutorbot', courseId, 'config']})
	}

	return (
		<>
			<div className="flex min-h-[32px] flex-wrap items-center justify-between gap-y-[12px]">
				<EducatorTabs />
				<div className="flex items-center gap-x-[8px]">
					{botConfig?.enabled && botConfig.locked && (
						<div className="flex items-center rounded-[2px] bg-uom-blue-light-25 px-[8px] py-[4px] text-[14px] text-uom-heritage-100">
							<MdInfo className="size-[20px]" />
							<div className="pl-[4px] pr-[2px] leading-[20px]">To update data set, hide student access from the</div>
							<Link
								to="/course/$courseId/access"
								params={{courseId}}
								className="underline"
							>
								access tab
							</Link>
						</div>
					)}
					{botConfig?.is_locked_by_current_user && !isUpdating && !indexModulesIsPending && !botConfig.enabled && modules.length > 0 && (
						<div className={clsx(overSize ? 'bg-uom-red-light-25' : 'bg-uom-blue-light-25', 'flex items-center rounded-[2px] px-[8px] py-[4px] text-[14px] text-uom-heritage-100')}>
							{overSize ? <MdError className="size-[20px]" /> : <MdCloudUpload className="size-[20px]" />}
							<div className="pl-[4px] pr-[2px] leading-[20px]">
								Max data set size: {MAX_DATA_SET_SIZE}.{' '}
								{selectedSize > 0 && (
									<>
										Selected: <span className="font-[700]">{convertBytesNumberToString(selectedSize)}</span>
									</>
								)}
							</div>
						</div>
					)}
					<button
						onClick={() => {
							void update()
						}}
						disabled={indexModulesIsPending || Object.keys(selectedResources).length === 0 || isUpdating || !botConfig?.is_locked_by_current_user || overSize || botConfig.enabled}
						className="bg-uom-blue-light-100 px-[12px] py-[6px] text-center text-[14px] font-[700] text-uom-heritage-100 hover:bg-uom-blue-light-50 active:bg-uom-blue-light-75 disabled:cursor-not-allowed disabled:bg-uom-blue-dark-50 disabled:text-uom-blue-dark-100"
					>
						{botConfig?.dataset_id == null ? 'Create' : 'Update'} data set
					</button>
				</div>
			</div>
			<div className="mt-[24px]">
				{!botConfig?.locked && (
					<div className="mb-[24px] flex max-w-content items-center gap-[10px] bg-uom-blue-light-25 p-[12px]">
						<div className="shrink-0 bg-uom-blue-light-50 p-[6px]">
							<MdInfo className="size-[20px]" />
						</div>
						<div className="text-uom-heritage-100">
							To update LMS resources, you must prevent simultaneous edits by other admin users by toggling the 'lock for editing' option in the{' '}
							<Link
								to="/course/$courseId/access"
								params={{courseId}}
								className="underline"
							>
								access tab
							</Link>
							.
						</div>
					</div>
				)}
				<h1 className="font-lead text-[30px] font-[500] leading-[110%] text-uom-heritage-100">LMS resources</h1>
				<p className="mt-[8px] min-h-[39px] max-w-content text-[18px]">
					The AI has been programmed to reference the subject resources uploaded to the LMS. If you don’t want the AI to reference a particular resource, you can deselect it below and update.{' '}
				</p>
				<EducatorSiteDisabled>
					<div className="mt-[24px] flex items-end justify-between">
						<button
							onClick={() => {
								void indexModules()
								setSelectedResources({})
							}}
							disabled={!botConfig?.locked || indexModulesIsPending || isUpdating}
							className="bg-uom-blue-light-100 px-[12px] py-[8px] text-[14px] font-[700] text-uom-heritage-100 hover:bg-uom-blue-light-50 active:bg-uom-blue-light-75 disabled:cursor-not-allowed disabled:bg-uom-blue-dark-50 disabled:text-uom-blue-dark-100"
						>
							Fetch modules from LMS
						</button>
						{!isUpdating && !indexModulesIsPending && botConfig?.locked && dataSet?.status === 'Ready' && botConfig.enabled && (
							<div className="flex items-center gap-x-[4px] rounded-[2px] bg-uom-green-light-25 px-[8px] py-[4px] text-[14px] text-uom-heritage-100">
								<MdCheckCircle className="size-[16px]" />
								Data set live
							</div>
						)}
					</div>
					{indexModulesIsPending && (
						<div className="mt-[26px] flex items-center gap-x-[8px]">
							<Spinner className="size-[24px] text-uom-heritage-100" />
							<div className="text-[14px] text-uom-grey-dark-75">Fetching current modules, pages and files from LMS</div>
						</div>
					)}
					{isUpdating && (
						<div className="mt-[26px] flex items-center gap-x-[8px]">
							<Spinner className="size-[24px] text-uom-heritage-100" />
							Updating data may take a few minutes. You'll be emailed when it's complete, so feel free to exit this page in the meantime.
						</div>
					)}
					<div className="flex flex-col">
						{!isUpdating && !indexModulesIsPending && botConfig?.locked && dataSet?.status === 'Ready' && !botConfig.enabled && (
							<div className="mt-[24px] flex max-w-content items-center gap-[10px] bg-uom-green-light-25 p-[12px] text-uom-heritage-100">
								<div className="flex size-[32px] shrink-0 items-center justify-center bg-uom-green-light-50">
									<MdCheckCircle className="size-[20px]" />
								</div>
								<div>
									The data set has been successfully updated. You can now show the learning assistant in the
									<Link
										to="/course/$courseId/access"
										params={{courseId}}
										className="inline pl-[2px] underline"
									>
										access tab.
									</Link>
								</div>
							</div>
						)}
						{!isUpdating && !indexModulesIsPending && dataSourcesHasErrors && botConfig?.locked && (
							<div className="items-top my-[12px] flex max-w-content gap-[10px] bg-uom-yellow-25 p-[12px] text-uom-heritage-100 first:mt-[24px]">
								<div className="flex size-[32px] shrink-0 items-center justify-center bg-uom-yellow-50">
									<MdError className="size-[22px]" />
								</div>
								<div>
									<p>
										The dataset has been updated successfully, but there were errors. You'll find error details in the modules below. We've excluded the problematic pages and files from the dataset.
									</p>
									<p className="pt-[1rem]">Once the errors are resolved, you can fetch the modules again to update the data set.</p>
								</div>
							</div>
						)}

						{!isUpdating && !indexModulesIsPending && botConfig?.locked && dataSet?.status === 'Error' && (
							<div className="items-top my-[12px] flex max-w-content gap-[10px] bg-uom-red-light-25 p-[12px] text-uom-heritage-100 first:mt-[24px]">
								<div className="flex size-[32px] shrink-0 items-center justify-center bg-uom-red-light-50">
									<MdError className="size-[22px]" />
								</div>
								<div>
									<p>Error</p>
									<p className="pt-[1rem]">{dataSet.comment}</p>
								</div>
							</div>
						)}
					</div>

					{!isUpdating && !indexModulesIsPending && modules.length > 0 && (
						<div className={clsx(!botConfig?.locked && 'pointer-events-none opacity-30', 'mt-[24px] flex flex-col gap-y-[12px]')}>
							{modules.map((module) => (
								<ModuleRow
									key={module.id}
									module={module}
								/>
							))}
						</div>
					)}
				</EducatorSiteDisabled>
			</div>
		</>
	)
}

import {useState} from 'react'
import {MdCheck, MdContentCopy} from 'react-icons/md'
import {Tooltip, TooltipContent, TooltipTrigger} from '~/components/Tooltip.tsx'

export function CopyButton({text, tooltip = 'Copy text'}: {text: string; tooltip?: string}) {
	const [isChecked, setIsChecked] = useState(false)

	function copy() {
		void navigator.clipboard.writeText(text)
		setIsChecked(true)
		setTimeout(() => {
			setIsChecked(false)
		}, 5000)
	}

	return (
		<Tooltip placement="bottom">
			<TooltipTrigger>
				<button
					onClick={copy}
					className="mt-[12px] flex size-[32px] items-center justify-center border border-uom-heritage-100 hover:bg-uom-blue-light-25 active:bg-uom-grey-light-25"
					aria-description={isChecked ? 'Copied' : tooltip}
				>
					{isChecked ? <MdCheck className="size-[16px] text-uom-heritage-100" /> : <MdContentCopy className="size-[16px] text-uom-heritage-100" />}
				</button>
			</TooltipTrigger>
			{!isChecked && <TooltipContent className="z-[999] bg-uom-blue-dark-100 px-[8px] py-[4px] text-white">{tooltip}</TooltipContent>}
		</Tooltip>
	)
}

import {createFileRoute} from '@tanstack/react-router'
import {useAtom} from 'jotai'
import {useEffect, useRef} from 'react'
import {MdOutbond} from 'react-icons/md'
import {useBotConfig, useThread} from '~/api.ts'
import {AilaHeading} from '~/components/AilaHeading.tsx'
import {CopyButton} from '~/components/CopyButton.tsx'
import {PendingMessage} from '~/components/PendingMessage.tsx'
import {SourcesHeading} from '~/components/SourcesHeading.tsx'
import {ThreadMenu} from '~/components/ThreadMenu.tsx'
import {Tooltip, TooltipContent, TooltipTrigger} from '~/components/Tooltip.tsx'
import {pendingMessageAtom, scrollToBottomOfMessageAtom} from '~/state.ts'
import {messageToClipboardString} from '~/util.ts'

export const Route = createFileRoute('/_student-layout/course/$courseId/threads/$threadId')({
	component: Thread,
})

function Thread() {
	const threadId = Route.useParams().threadId
	const botConfig = useBotConfig()
	const {data: thread} = useThread(threadId)
	const [pendingMessage] = useAtom(pendingMessageAtom)
	const [, setScrollToBottomOfMessage] = useAtom(scrollToBottomOfMessageAtom)

	const pendingResponseRef = useRef<HTMLDivElement>(null)
	const endOfMessagesRef = useRef<HTMLDivElement>(null)

	// These two are separate because we want to scroll to the bottom of the thread instant when the messages change, but we want to scroll to the pending message when it changes
	useEffect(() => {
		setScrollToBottomOfMessage((prev) => prev + 1)
	}, [thread, setScrollToBottomOfMessage])

	useEffect(() => {
		pendingResponseRef.current?.scrollIntoView({behavior: 'smooth'})
		endOfMessagesRef.current?.scrollIntoView({behavior: 'smooth'})
	}, [pendingMessage])

	const socratic = thread?.prompt_template_id === 'SOCRATIC'
	const messages = socratic ? thread.ui_messages.slice(1) : thread?.ui_messages

	return (
		<>
			<div className="absolute right-[24px] top-[16px] flex flex-col items-end gap-[12px] md:top-[24px] lg:right-[82px]">
				<div className="hidden rounded-[2px] bg-uom-pink-25 px-[8px] py-[2px] text-[14px] leading-[20px] text-uom-heritage-100 md:block">{socratic ? 'Socratic tutor' : 'Chat'}</div>
				<ThreadMenu threadId={threadId} />
			</div>
			<AilaHeading />
			<div className="mb-[24px] mt-[12px] text-[18px] leading-[140%]">
				{socratic ? (
					<div className="max-w-max rounded-[2px] bg-uom-yellow-25 px-[8px] py-[2px] text-[14px] leading-[20px] text-uom-heritage-100">{thread.ui_messages[0].content}</div>
				) : (
					<div>
						Ask me anything about <span className="font-[600]">{botConfig?.tutor_bot_course_name.split('(')[0]?.trim()}</span> ({botConfig?.sis_course_id})
					</div>
				)}
			</div>
			{messages?.map((message, index) => (
				<div
					key={index}
					className="flex flex-col gap-y-[24px] border-t border-uom-grey-dark-25 py-[24px]"
				>
					{message.sources?.length != null && message.sources.length > 0 && (
						<div className="flex flex-col">
							<SourcesHeading />
							<div className="mt-[12px] flex gap-[24px] overflow-x-auto pb-[8px] md:flex-wrap">
								{message.sources.map((source) => (
									<Tooltip
										key={source.uri}
										placement="bottom"
									>
										<TooltipTrigger className="ative:bg-white group w-[154px] shrink-0 cursor-pointer gap-y-[5px] border border-uom-grey-light-50 bg-uom-grey-light-25 p-[8px] hover:bg-white active:border-uom-heritage-100">
											<a
												href={source.source}
												target="_blank"
												rel="noreferrer"
												className="flex h-full cursor-pointer flex-col justify-between gap-y-[5px]"
											>
												<div
													className="line-clamp-2 overflow-ellipsis break-all text-[14px] leading-[120%] text-uom-heritage-100 group-hover:underline"
													dangerouslySetInnerHTML={{__html: source.title.replaceAll('_', '_<wbr>')}}
												/>
												<div className="flex items-center justify-between gap-x-[4px]">
													<div className="text-[14px] leading-[120%] text-uom-grey-dark-75 group-hover:text-uom-grey-dark-100">View source</div>
													<MdOutbond className="size-[18px] shrink-0 text-uom-heritage-100" />
												</div>
											</a>
										</TooltipTrigger>
										<TooltipContent className="mt-[4px] bg-uom-blue-dark-100 px-[8px] py-[4px] text-white">Open in a new tab</TooltipContent>
									</Tooltip>
								))}
							</div>
						</div>
					)}
					<div className="flex flex-col">
						{message.role === 'assistant' && !(socratic && index === 0) && <AilaHeading className="pb-[12px]" />}
						<div className="whitespace-pre-line break-words text-[18px] leading-[140%]">{message.content}</div>
						{message.role === 'assistant' && (
							<div className="max-w-max">
								<CopyButton
									text={messageToClipboardString(message)}
									tooltip="Copy response"
								/>
							</div>
						)}
					</div>
				</div>
			))}
			{pendingMessage != null && <PendingMessage socratic={socratic} />}
			<div ref={endOfMessagesRef} />
		</>
	)
}

import {createRootRoute, Outlet, redirect} from '@tanstack/react-router'
import {Navbar} from '~/components/Navbar.tsx'
import {WindowsSizeIndicator} from '~/components/WindowsSizeIndicator.tsx'
import {decodeJwt} from '~/util.ts'

export const Route = createRootRoute({
	component: Root,
	loader: () => {
		const token = localStorage.getItem('token')
		if (token == null && window.location.pathname !== '/no-token') {
			redirect({to: '/no-token', throw: true})
			return
		}
		if (window.location.pathname === '/') {
			const searchParams = new URLSearchParams(new URL(window.location.href).search)
			const jwt = decodeJwt(token)
			const tokenCourseId = jwt.payload.canvasSisCourseId
			const tokenRole = jwt.payload.role
			redirect({
				to: tokenRole === 'student' || searchParams.get('preview') === 'true' ? `/course/${tokenCourseId}/threads` : `/course/${tokenCourseId}/access`,
				throw: true,
			})
		}
	},
})

function Root() {
	if (window.location.pathname === '/no-token') return <Outlet />

	return (
		<div className="flex h-full flex-col">
			<Navbar />
			<Outlet />
			{import.meta.env.DEV && <WindowsSizeIndicator />}
		</div>
	)
}

export const SourcesIcon = ({className}: {className: string}) => (
	<svg
		className={className}
		viewBox="0 0 20 20"
		xmlns="http://www.w3.org/2000/svg"
		fill="none"
	>
		<path
			d="M18 4h-8L8 2H2C.9 2 .01 2.9.01 4L0 16c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2Zm-6 10H4v-2h8v2Zm4-4H4V8h12v2Z"
			fill="currentColor"
		/>
	</svg>
)

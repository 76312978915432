import {atom} from 'jotai'
import {atomWithStorage, createJSONStorage} from 'jotai/utils'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const jotaiSessionStorage = createJSONStorage<any>(() => sessionStorage)

export const isShowMobileMenuAtom = atom(false)

export const pendingMessageAtom = atom<string | null>(null)

export const renameThreadIdAtom = atom<string | null>(null)
export const deleteThreadIdAtom = atom<string | null>(null)

export const scrollToBottomOfMessageAtom = atom(0)

export const selectedResourcesAtom = atomWithStorage<Record<string, boolean>>(`selected-resources`, {}, jotaiSessionStorage)

import {QueryClientProvider} from '@tanstack/react-query'
import {createRouter, RouterProvider} from '@tanstack/react-router'
import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import {queryClient, refreshToken} from '~/api.ts'
import {routeTree} from './routeTree.gen'

if (window.location.pathname === '/') {
	const token = new URLSearchParams(new URL(window.location.href).search).get('ltik')
	//console.log(token, JSON.stringify(decodeJwt(token)))
	if (token != null && token.length > 0) {
		localStorage.setItem('token', token)
	}
}

setInterval(
	() => {
		void refreshToken()
	},
	1000 * 60 * 60, // 1 hour
)

// Create a new router instance
const router = createRouter({routeTree})

// Register the router instance for type safety
declare module '@tanstack/react-router' {
	interface Register {
		router: typeof router
	}
}

let root = document.getElementById('root')
if (root == null) {
	root = document.createElement('div')
	root.id = 'root'
	document.body.appendChild(root)
}

ReactDOM.createRoot(root).render(
	<React.StrictMode>
		<QueryClientProvider client={queryClient}>
			<RouterProvider router={router} />
		</QueryClientProvider>
		{/*<NetworkErrorHandling />*/}
	</React.StrictMode>,
)

import {twMerge} from 'tailwind-merge'
import {AilaIcon} from '~/assets/AilaIcon.tsx'

export function AilaHeading({className}: {className?: string}) {
	return (
		<div className={twMerge('flex items-center gap-x-[8px]', className)}>
			<AilaIcon className="size-[32px] bg-uom-heritage-100 text-uom-yellow-75" />
			<div className="text-[18px] text-uom-heritage-100">Aila</div>
		</div>
	)
}

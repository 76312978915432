import {
	autoUpdate,
	flip,
	FloatingPortal,
	offset,
	type Placement,
	shift,
	useDismiss,
	useFloating,
	type UseFloatingReturn,
	useFocus,
	type UseFocusProps,
	useHover,
	type UseHoverProps,
	useInteractions,
	useMergeRefs,
	useRole,
} from '@floating-ui/react'
import * as React from 'react'

interface TooltipOptions extends UseHoverProps, UseFocusProps {
	initialOpen?: boolean
	placement?: Placement
	open?: boolean
	onOpenChange?: (open: boolean) => void
}

interface UseTooltipReturn extends UseFloatingReturn {
	open: boolean
	setOpen: (open: boolean) => void
	getReferenceProps: (props?: React.HTMLProps<Element>) => Record<string, unknown>
	getFloatingProps: (props?: React.HTMLProps<HTMLElement>) => Record<string, unknown>
}

// eslint-disable-next-line react-refresh/only-export-components
export function useTooltip({initialOpen = false, placement = 'top', open: controlledOpen, onOpenChange: setControlledOpen, enabled = true}: TooltipOptions = {}): UseTooltipReturn {
	const [uncontrolledOpen, setUncontrolledOpen] = React.useState(initialOpen)

	const open = controlledOpen ?? uncontrolledOpen
	const setOpen = setControlledOpen ?? setUncontrolledOpen

	const data = useFloating({
		placement,
		open,
		onOpenChange: setOpen,
		whileElementsMounted: autoUpdate,
		middleware: [
			offset(5),
			flip({
				crossAxis: placement.includes('-'),
				fallbackAxisSideDirection: 'start',
				padding: 5,
			}),
			shift({padding: 5}),
		],
	})

	const context = data.context

	const hover = useHover(context, {
		move: false,
		enabled: controlledOpen == null && enabled,
	})
	const focus = useFocus(context, {
		enabled: controlledOpen == null && enabled,
	})
	const dismiss = useDismiss(context)
	const role = useRole(context, {role: 'tooltip'})

	const interactions = useInteractions([hover, focus, dismiss, role])

	return React.useMemo(
		() => ({
			open: open && enabled,
			setOpen,
			...interactions,
			...data,
		}),
		[open, setOpen, interactions, data, enabled],
	)
}

type ContextType = ReturnType<typeof useTooltip> | null

const TooltipContext = React.createContext<ContextType>(null)

// eslint-disable-next-line react-refresh/only-export-components
export const useTooltipContext = () => {
	const context = React.useContext(TooltipContext)

	if (context == null) {
		throw new Error('Tooltip components must be wrapped in <Tooltip />')
	}

	return context
}

export function Tooltip({children, ...options}: {children: React.ReactNode} & TooltipOptions) {
	// This can accept any props as options, e.g. `placement`,
	// or other positioning options.
	const tooltip = useTooltip(options)
	return <TooltipContext.Provider value={tooltip}>{children}</TooltipContext.Provider>
}

export const TooltipTrigger = React.forwardRef<HTMLElement, React.HTMLProps<HTMLElement> & {asChild?: boolean}>(function TooltipTrigger({children, asChild = false, ...props}, propRef) {
	const context = useTooltipContext()
	// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-explicit-any
	const childrenRef = (children as any).ref
	// eslint-disable-next-line @typescript-eslint/no-unsafe-argument
	const ref = useMergeRefs([context.refs.setReference, propRef, childrenRef])

	// `asChild` allows the user to pass any element as the anchor
	if (asChild && React.isValidElement(children)) {
		return React.cloneElement(
			children,
			// eslint-disable-next-line @typescript-eslint/no-unsafe-argument
			context.getReferenceProps({
				ref,
				...props,
				...children.props,
				'data-state': context.open ? 'open' : 'closed',
			}),
		)
	}

	return (
		<div
			ref={ref}
			// The user can style the trigger based on the state
			data-state={context.open ? 'open' : 'closed'}
			{...context.getReferenceProps(props)}
		>
			{children}
		</div>
	)
})

export const TooltipContent = React.forwardRef<HTMLDivElement, React.HTMLProps<HTMLDivElement>>(function TooltipContent({style, ...props}, propRef) {
	const context = useTooltipContext()
	const ref = useMergeRefs([context.refs.setFloating, propRef])

	if (!context.open) return null

	return (
		<FloatingPortal>
			<div
				ref={ref}
				style={{
					...context.floatingStyles,
					...style,
				}}
				{...context.getFloatingProps(props)}
			/>
		</FloatingPortal>
	)
})

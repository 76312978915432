import {createFileRoute} from '@tanstack/react-router'
import {WindowsSizeIndicator} from '~/components/WindowsSizeIndicator.tsx'

export const Route = createFileRoute('/no-token')({
	component: NoToken,
})

const LMS_URL = import.meta.env.VITE_LMS_URL

function NoToken() {
	return (
		<div className="h-screen overflow-y-auto overflow-x-hidden bg-uom-heritage-100">
			<div className="flex min-h-min flex-col items-center justify-center p-[24px] leading-none children:flex-shrink-0 md:h-full">
				<div className="flex flex-col flex-wrap items-center justify-center gap-[20px] md:flex-row">
					<img
						alt="University of Melbourne"
						src="/uom_logo.svg"
						className="h-[90px]"
					/>
					<img
						alt="Aila"
						src="/aila_logo.svg"
						className="h-[56px]"
					/>
				</div>
				<div className="pt-[32px] text-center font-lead text-[64px] text-white md:pt-[48px]">Your session has expired</div>
				<div className="font max-w-[1080px] pt-[48px] text-center text-[38px] font-light leading-[54px] text-gray-200">
					The AI Learning Assistant is only accessible through the University of Melbourne's Learning Management System (LMS). Please open a new session in the LMS.
				</div>
				<a
					href={LMS_URL}
					className="mt-[32px] bg-uom-blue-light-100 px-[18px] py-[12px] text-[24px] font-medium text-uom-heritage-100 hover:bg-uom-blue-light-25 active:bg-uom-blue-light-75"
				>
					Login to the LMS
				</a>
				{import.meta.env.DEV && <WindowsSizeIndicator />}
			</div>
		</div>
	)
}

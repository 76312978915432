import {ErrorModal} from '~/components/ErrorModal.tsx'

interface Props {
	itemType: string
	open: boolean
	onClose: () => void
}

export const UniqueNameRequiredModal = ({itemType, open, onClose}: Props) => {
	return (
		<ErrorModal
			title={`Unique ${itemType} name required`}
			open={open}
			onClose={onClose}
		>
			<p>You already have a {itemType} with this name.</p>
			<p className="mt-[1rem]">Create a unique name.</p>
		</ErrorModal>
	)
}

import type {Jwt, Thread, UIMessage} from '~/model.ts'

export function decodeJwt(token: string | null | undefined) {
	if (token == null) throw new Error('Token is null or undefined')
	if (token.length === 0) throw new Error('Token is empty')

	// Split the token into three parts
	const parts = token.split('.')

	// Check if the token has the correct format
	if (parts.length !== 3) throw new Error('Token does not have the correct format')

	// Decode the base64url-encoded header and payload
	const header = JSON.parse(atob(parts[0])) as unknown
	const payload = JSON.parse(atob(parts[1])) as unknown

	const jwt = {
		header,
		payload,
	} as Jwt

	return jwt
}

export function convertBytesNumberToString(bytes: number): string {
	const units = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
	const exponent = Math.floor(Math.log(bytes) / Math.log(1024))
	const value = bytes / Math.pow(1024, exponent)

	return Number.isInteger(value) ? `${value}${units[exponent]}` : `${value.toFixed(1)}${units[exponent]}`
}

export function convertBytesStringToNumber(bytesString: string): number {
	const match = /^(\d+(?:\.\d+)?)\s*([KMGTPEZY]?B|[BKMGTPEZY]|)$/i.exec(bytesString)

	if (!match) {
		throw new Error('Invalid bytes string format')
	}

	const value = parseFloat(match[1])
	const unit = match[2].toUpperCase()

	const units = {
		B: 1,
		KB: 1024,
		MB: 1024 ** 2,
		GB: 1024 ** 3,
		TB: 1024 ** 4,
		PB: 1024 ** 5,
		EB: 1024 ** 6,
		ZB: 1024 ** 7,
		YB: 1024 ** 8,
	} as Record<string, number | undefined>

	const multiplier: number = units[unit] ?? 1

	return Math.round(value * multiplier)
}

export function copyThreadToClipboard(thread: Thread) {
	const string = thread.ui_messages.reduce((build, message) => {
		build = build + (message.role === 'assistant' ? 'Learning Assistant' : 'User') + ': \n'

		build = build + message.content + '\n\n'
		if (message.sources != null && message.sources.length > 0) {
			build = build + 'Sources:\n'
			build = build + message.sources.map((source) => `${source.title} ${source.source}`).join('\n') + '\n\n'
		}
		return build
	}, '')
	void navigator.clipboard.writeText(string)
}

export function messageToClipboardString(message: UIMessage) {
	let string = message.content
	if (message.sources != null && message.sources.length > 0) {
		string += '\nSources:\n'
		string += message.sources.map((source) => `${source.title} ${source.source}`).join('\n') + '\n\n'
	}
	return string
}

export function convertToISODateWithTimezone(datetimeUtc: number): string {
	const utcDate = new Date(datetimeUtc)
	const localDate = new Date(utcDate.getTime() - utcDate.getTimezoneOffset() * 60000)
	const offset = localDate.getTimezoneOffset()
	const offsetHours = Math.abs(offset / 60)
	const offsetMinutes = Math.abs(offset % 60)
	const sign = offset > 0 ? '-' : '+'

	const pad = (num: number): string => (num < 10 ? '0' + num.toString() : num.toString())

	const timezoneOffset = `${sign}${pad(offsetHours)}:${pad(offsetMinutes)}`

	return localDate
		.toISOString()
		.replace(/.\d+Z$/g, 'Z') // remove milliseconds
		.replace('Z', timezoneOffset)
}

export function millisecondsToDaysHoursMinutesAndSeconds(milliseconds: number) {
	const days = Math.floor(milliseconds / (1000 * 60 * 60 * 24))
	const hours = Math.floor(milliseconds / (1000 * 60 * 60))
	const minutes = Math.floor((milliseconds % (1000 * 60 * 60)) / (1000 * 60))
	const seconds = Math.floor((milliseconds % (1000 * 60)) / 1000)
	return `${days > 0 ? days.toString() + 'd' : ''}${hours > 0 ? hours.toString() + 'h' : ''}${minutes > 0 ? minutes.toString() + 'm' : ''}${seconds > 0 ? seconds.toString() + 's' : ''}`
}

export function convertIsoDateToRevisionsFormat(dateString: string): string {
	const date = new Date(dateString)

	const options: Intl.DateTimeFormatOptions = {
		day: 'numeric',
		month: 'long',
		year: 'numeric',
		hour: '2-digit',
		minute: '2-digit',
		hour12: false,
	}

	return date.toLocaleString('en-US', options)
}

export function msToHoursMinutes(ms: number) {
	if (ms <= 0) return ''
	const hoursUntilUnlock = Math.floor(ms / (1000 * 60 * 60))
	const minutesUntilUnlock = Math.floor((ms % (1000 * 60 * 60)) / (1000 * 60))
	return `${hoursUntilUnlock > 0 ? hoursUntilUnlock.toString() + 'h' : ''} ${minutesUntilUnlock > 0 ? minutesUntilUnlock.toString() + 'm' : ''}`
}

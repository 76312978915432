import {twMerge} from 'tailwind-merge'
import {SourcesIcon} from '~/assets/SourcesIcon.tsx'

export function SourcesHeading({className}: {className?: string}) {
	return (
		<div className={twMerge('flex items-center gap-x-[8px]', className)}>
			<div className="flex size-[32px] items-center justify-center bg-uom-heritage-100">
				<SourcesIcon className="ml-[1px] size-[20px] text-uom-yellow-75" />
			</div>
			<div className="text-[18px] text-uom-heritage-100">Sources</div>
		</div>
	)
}

import {Link} from '@tanstack/react-router'
import {clsx} from 'clsx'
import {MdCheckCircle, MdLock, MdOutlineRemoveRedEye, MdUnpublished} from 'react-icons/md'
import {useBotConfig} from '~/api.ts'
import {LockOpen} from '~/assets/LockOpen.tsx'
import {useCourseId} from '~/hooks/useCourseId.ts'

export const EducatorSubNavbar = () => {
	const courseId = useCourseId()
	const botConfig = useBotConfig()

	return (
		<nav className="flex shrink-0 flex-wrap items-center justify-between gap-[12px] bg-uom-grey-light-25 px-[32px] py-[8px] lg:px-[80px]">
			<Link
				to="/course/$courseId/access"
				params={{courseId}}
				className="flex shrink-0 items-center"
			>
				<div className="text-[14px] text-uom-heritage-100">Admin</div>
				<div className={clsx(botConfig?.is_locked_by_current_user ? 'bg-uom-blue-light-100' : 'bg-white', 'ml-[8px] rounded-[2px] p-[6px]')}>
					{botConfig?.is_locked_by_current_user ? <MdLock className="size-[16px]" /> : <LockOpen className="size-[16px]" />}
				</div>
				{import.meta.env.VITE_SHOW_STUDENT_LOCK === 'true' && (
					<>
						<div className="ml-[12px] text-[14px] text-uom-heritage-100">Students</div>
						<div className={clsx(botConfig?.enabled ? 'bg-uom-blue-light-100' : 'bg-white', 'ml-[8px] rounded-[2px] p-[6px]')}>
							{botConfig?.enabled ? <MdCheckCircle className="size-[16px]" /> : <MdUnpublished className="size-[16px]" />}
						</div>
					</>
				)}
			</Link>
			<a
				target="_blank"
				href={`/course/${courseId}/threads`}
				onClick={(e) => {
					if (botConfig?.status !== 'Ready') e.preventDefault()
				}}
				className={clsx(
					botConfig?.status === 'Ready'
						? 'border-uom-heritage-100 text-uom-heritage-100 hover:bg-uom-blue-light-25 active:bg-white'
						: 'cursor-not-allowed border-uom-grey-dark-75 text-uom-grey-dark-75',
					'flex min-w-min items-center gap-x-[10px] border px-[12px] py-[10px] text-[14px] font-[700]',
				)}
				rel="noreferrer"
			>
				<MdOutlineRemoveRedEye className="h-[16px] shrink-0" />
				<div className="leading-none">View student experience</div>
			</a>
		</nav>
	)
}

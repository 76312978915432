import {createFileRoute, Link} from '@tanstack/react-router'
import {MdChat} from 'react-icons/md'
import {useApiContext} from '~/api.ts'
import {BranchIcon} from '~/assets/BranchIcon.tsx'
import {AilaHeading} from '~/components/AilaHeading.tsx'

export const Route = createFileRoute('/_student-layout/course/$courseId/threads')({
	component: NewThread,
})

function NewThread() {
	const {data: context} = useApiContext()
	const {courseId} = Route.useParams()

	return (
		<>
			<AilaHeading />
			<div className="mt-[12px] text-[18px] leading-[140%]">Hello {context?.context.given_name},</div>
			<div className="mt-[.6em] text-[18px] leading-[140%]">Choose from the two pathways below for how you'd like to interact with the LMS teaching materials for this subject.</div>

			<div className="mt-[12px] grid gap-[16px] sm:grid-cols-[1fr,1fr] md:mt-[24px] md:gap-[24px]">
				<Link
					to="/course/$courseId/threads/chat"
					params={{courseId}}
					className="flex flex-col border border-uom-grey-dark-50 bg-white p-[12px] hover:bg-uom-blue-light-25 active:bg-uom-grey-light-25"
				>
					<div className="flex size-[32px] items-center justify-center bg-uom-heritage-100">
						<MdChat className="size-[20px] text-uom-pink-75" />
					</div>
					<div className="mt-[8px] text-[18px] font-[600] text-uom-heritage-100">
						Chat <span className="font-normal">(ask a question)</span>
					</div>
					<p className="mt-[4px] leading-[140%] text-uom-grey-dark-75">Ask the AI anything about the subject. It will provide answers by referencing content within the UniMelb LMS.</p>
				</Link>

				<Link
					to="/course/$courseId/threads/socratic-tutor"
					params={{courseId}}
					className="flex flex-col border border-uom-grey-dark-50 p-[12px] hover:bg-uom-blue-light-25 active:bg-uom-grey-light-25"
				>
					<div className="flex size-[32px] items-center justify-center bg-uom-heritage-100">
						<BranchIcon className="size-[24px] text-uom-pink-75" />
					</div>
					<div className="mt-[8px] text-[18px] font-[600] text-uom-heritage-100">
						Socratic tutor <span className="font-normal">(select a topic)</span>
					</div>
					<p className="mt-[4px] leading-[140%] text-uom-grey-dark-75">Allow the AI act as your Socratic tutor. It will guide you through topics within the UniMelb LMS specific to this subject.</p>
				</Link>
			</div>
		</>
	)
}

import {RefObject, useLayoutEffect, useState} from 'react'

export const useIsOverflow = (ref: RefObject<HTMLElement>, callback?: (isOverflow: boolean) => void) => {
	const [isOverflow, setIsOverflow] = useState(false)

	useLayoutEffect(() => {
		const {current} = ref

		if (current) {
			const hasOverflow = current.scrollWidth > current.clientWidth
			setIsOverflow(hasOverflow)

			if (callback) callback(hasOverflow)
		}
	}, [callback, ref])

	return isOverflow
}

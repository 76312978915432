import {Switch} from '@headlessui/react'
import {clsx} from 'clsx'

interface Props {
	checked: boolean
	disabled?: boolean
	onChange: (checked: boolean) => void
}

export const Toggle = ({checked, disabled, onChange}: Props) => {
	return (
		<Switch
			checked={checked}
			onChange={onChange}
			disabled={disabled}
			className={clsx(
				checked ? (disabled ? 'border-uom-heritage-50 bg-uom-blue-light-50' : 'border-uom-heritage-100 bg-uom-blue-light-100') : 'border-uom-heritage-25 bg-uom-heritage-25',
				'relative inline-flex h-[24px] w-[48px] flex-shrink-0 cursor-pointer items-center rounded-full border-2 transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-uom-heritage-100 focus:ring-offset-2',
				!checked && disabled && 'opacity-60',
			)}
		>
			<span className="sr-only">Use setting</span>
			<span
				aria-hidden="true"
				className={clsx(
					checked ? 'translate-x-[24px]' : 'translate-x-0',
					'pointer-events-none inline-block size-[20px] transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out',
				)}
			/>
		</Switch>
	)
}

import {createFileRoute, useNavigate} from '@tanstack/react-router'
import {MdArrowForward, MdRefresh} from 'react-icons/md'
import {useChatCompletion, useChatTopics} from '~/api.ts'
import {AilaPing} from '~/assets/AilaPing.tsx'
import {SparklesIcon} from '~/assets/SparklesIcon.tsx'
import {AilaHeading} from '~/components/AilaHeading.tsx'

export const Route = createFileRoute('/_student-layout/course/$courseId/threads/socratic-tutor')({
	component: () => <Subject />,
})

const Subject = () => {
	const {courseId} = Route.useParams()
	const {data: topics, isFetching: topicsIsLoading, refetch: refetchTopics} = useChatTopics()
	const {mutateAsync: createChat, isPending: createChatIsPending} = useChatCompletion(undefined, true)
	const navigate = useNavigate()

	/*	useEffect(async () => {
		if (topics == null && !topicsIsPending) {
			await createTopics()
		}
	}, [createTopics, topics])*/

	async function createSocraticChat(topic: string) {
		const newChat = await createChat({message: topic})
		void navigate({to: '/course/$courseId/threads/$threadId', params: {courseId: courseId, threadId: newChat.SK}})
	}

	return (
		<>
			<AilaHeading />
			<div className="mt-[12px] text-[18px] leading-[140%]">Select a topic below to begin.</div>
			{topicsIsLoading && (
				<>
					<div className="mt-[24px] grid gap-x-[24px] gap-y-[4px] border-t border-uom-grey-dark-25 pt-[24px] md:grid-cols-2">
						{Array(6)
							.fill(null)
							.map((_, index) => (
								<div
									key={index}
									className="h-[32px] w-full animate-pulse bg-uom-grey-light-25"
								/>
							))}
					</div>
					<div className="mt-[16px] flex flex-wrap items-center gap-x-[8px] text-uom-heritage-100 md:mt-[28px]">
						<SparklesIcon className="h-[22px] animate-spin pl-[4px]" />
						Generating topics...
					</div>
				</>
			)}
			{!topicsIsLoading && !createChatIsPending && (
				<>
					<div className="mt-[24px] grid gap-x-[24px] gap-y-[4px] border-t border-uom-grey-dark-25 pt-[24px] md:grid-cols-2">
						{topics
							?.map((topic) => topic.content)
							.map((topic, index) => (
								<button
									onClick={() => void createSocraticChat(topic)}
									key={index}
									className="group flex items-center justify-between gap-x-[8px] border border-transparent bg-uom-grey-light-25 p-[8px] text-[14px] hover:border-uom-grey-light-50 hover:bg-white active:border-uom-heritage-100"
								>
									<div className="self-start text-start">{topic}</div>
									<div className="flex size-[20px] items-center justify-center rounded-full bg-white group-hover:bg-uom-grey-light-25">
										<MdArrowForward className="size-[12px]" />
									</div>
								</button>
							))}
					</div>
					<button
						onClick={() => {
							void refetchTopics()
						}}
						className="test-[14px] mt-[8px] flex w-full items-center gap-x-[10px] self-end border border-uom-heritage-100 p-3 px-[12px] py-[10px] font-[700] leading-none text-uom-heritage-100 hover:bg-uom-blue-light-25 active:bg-uom-grey-light-25 md:mt-[12px] md:max-w-max"
					>
						<MdRefresh className="size-[16px]" /> Refresh topics
					</button>
				</>
			)}
			{createChatIsPending && (
				<div className="mt-[18px]">
					<AilaPing />
				</div>
			)}
		</>
	)
}

import {useAtom} from 'jotai/index'
import {AilaPing} from '~/assets/AilaPing.tsx'
import {AilaHeading} from '~/components/AilaHeading.tsx'
import {SourcesHeading} from '~/components/SourcesHeading.tsx'
import {pendingMessageAtom} from '~/state.ts'

export function PendingMessage({socratic}: {socratic?: boolean}) {
	const [pendingMessage] = useAtom(pendingMessageAtom)
	return (
		<>
			<div className="flex flex-col border-t border-uom-grey-dark-25 py-[24px]">
				<div className="whitespace-pre-line break-words text-[18px] leading-[140%]">{pendingMessage}</div>
			</div>
			<div className="flex flex-col border-t border-uom-grey-dark-25 pt-[24px]">
				{!socratic && (
					<>
						<SourcesHeading />
						<div className="mt-[12px] flex gap-[24px] overflow-x-hidden">
							<div className="h-[64px] w-[154px] shrink-0 animate-pulse bg-uom-grey-light-25" />
							<div className="h-[64px] w-[154px] shrink-0 animate-pulse bg-uom-grey-light-25" />
							<div className="h-[64px] w-[154px] shrink-0 animate-pulse bg-uom-grey-light-25" />
						</div>
					</>
				)}
				<AilaHeading className="pb-[18px] pt-[24px]" />
				<AilaPing />
			</div>
		</>
	)
}

export const AilaIcon = ({className}: {className?: string}) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		className={className}
		viewBox="0 0 32 32"
	>
		<path
			d="M18.555 20.435c-1.484.856-2.704 1.948-3.76 3.246a15.482 15.482 0 0 0-1.114 1.68c-.091.158-.362.08-.358-.103.014-.666-.034-1.33-.157-2.013a12.111 12.111 0 0 0-1.633-4.692 14.543 14.543 0 0 0-3.246-3.76 15.483 15.483 0 0 0-1.68-1.114c-.158-.09-.08-.362.102-.358.666.014 1.33-.033 2.014-.156a12.11 12.11 0 0 0 4.691-1.634 14.545 14.545 0 0 0 3.76-3.246c.415-.542.779-1.098 1.115-1.68.09-.158.362-.08.358.103-.015.665.033 1.33.156 2.013.215 1.64.763 3.259 1.633 4.692.857 1.484 1.948 2.703 3.247 3.76.542.414 1.098.778 1.68 1.114.158.09.08.362-.103.358-.666-.015-1.33.033-2.013.156a14.543 14.543 0 0 0-4.692 1.634ZM21.668 26.283a.409.409 0 0 1-.293-.125.432.432 0 0 1-.121-.3v-3.41c0-.113.043-.22.121-.3a.409.409 0 0 1 .586 0c.078.08.121.188.121.3v3.41a.433.433 0 0 1-.12.3.409.409 0 0 1-.294.125Z"
			fill="currentColor"
		/>
		<path
			d="M23.374 24.567h-3.409a.432.432 0 0 1-.301-.122.408.408 0 0 1 0-.585.432.432 0 0 1 .301-.122h3.409c.113 0 .221.044.301.122a.409.409 0 0 1 0 .585.432.432 0 0 1-.301.122Z"
			fill="currentColor"
		/>
	</svg>
)

import {createFileRoute, Outlet, useLocation, useNavigate, useParams} from '@tanstack/react-router'
import {getDefaultStore, useAtom} from 'jotai'
import {useRef} from 'react'
import {useDeleteThread} from '~/api.ts'
import {DeleteConfirmationModal} from '~/components/DeleteConfirmationModal.tsx'
import {LeftThreadsSidebar} from '~/components/LeftThreadsSidebar.tsx'
import {PromptInput} from '~/components/PromptInput.tsx'
import {StudentSiteDisabled} from '~/components/SiteDisabled.tsx'
import {deleteThreadIdAtom, scrollToBottomOfMessageAtom} from '~/state.ts'

export const Route = createFileRoute('/_student-layout')({
	component: StudentLayout,
})

function StudentLayout() {
	const {courseId} = useParams({strict: false})
	const scrollContainerRef = useRef<HTMLDivElement>(null)
	const [deleteThreadId, setDeleteThreadId] = useAtom(deleteThreadIdAtom)
	const {mutateAsync: deleteThread} = useDeleteThread()
	const navigate = useNavigate()
	const location = useLocation()

	function scrollToBottom() {
		scrollContainerRef.current?.scrollTo(0, scrollContainerRef.current.scrollHeight)
	}

	// Because the ref can't be passed though the Outlet
	getDefaultStore().sub(scrollToBottomOfMessageAtom, scrollToBottom)

	return (
		<div className="flex h-full">
			<LeftThreadsSidebar />
			<div className="flex grow flex-col justify-between">
				<StudentSiteDisabled>
					<div className="relative flex flex-1 flex-col items-center">
						<div
							ref={scrollContainerRef}
							className="h-full w-full overflow-y-auto overflow-x-hidden px-[16px] md:px-[24px]"
						>
							<div className="mx-auto flex w-full max-w-[724px] flex-col pt-[16px] md:py-[24px]">
								<Outlet />
							</div>
						</div>
					</div>
				</StudentSiteDisabled>
				<div className="max-h-max px-[16px] pt-[12px] md:pt-[24px]">
					<PromptInput />
				</div>
			</div>

			<DeleteConfirmationModal
				open={deleteThreadId != null}
				itemType="thread"
				onClose={() => {
					setDeleteThreadId(null)
				}}
				onDelete={() => {
					void (async () => {
						await deleteThread()
						if (location.pathname === `/threads/${deleteThreadId}`) {
							void navigate({to: '/course/$courseId/threads', params: {courseId: courseId ?? ''}})
						}
						setDeleteThreadId(null)
					})()
				}}
			/>
		</div>
	)
}

import {Dialog, DialogBackdrop, DialogPanel, Disclosure, DisclosureButton, DisclosurePanel} from '@headlessui/react'
import {MdClose, MdInfo} from 'react-icons/md'
import {useProjectChangelog, useProjectChangelogUserFullNames} from '~/api.ts'
import {ChevronDownIcon} from '~/assets/ChevronDownIcon.tsx'
import {CopyButton} from '~/components/CopyButton.tsx'
import {convertIsoDateToRevisionsFormat} from '~/util.ts'

interface Props {
	open: boolean
	setOpen: (open: boolean) => void
}

export function PromptEnhancementRevisions({open, setOpen}: Props) {
	const {data: revisions} = useProjectChangelog(open)
	const {data: userFullNames} = useProjectChangelogUserFullNames(revisions)

	return (
		<Dialog
			open={open}
			onClose={setOpen}
			className="relative z-10"
		>
			<DialogBackdrop
				transition
				className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity duration-500 ease-in-out data-[closed]:opacity-0"
			/>

			<div className="fixed inset-0 overflow-hidden">
				<div className="absolute inset-0 overflow-hidden">
					<div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
						<DialogPanel
							transition
							className="pointer-events-auto w-screen max-w-[728px] transform transition duration-500 ease-in-out data-[closed]:translate-x-full sm:duration-700"
						>
							<div className="flex h-full flex-col overflow-y-auto bg-white">
								<div className="flex shrink-0 items-center justify-end bg-uom-grey-light-25 px-[36px] py-[8px]">
									<button
										type="button"
										onClick={() => {
											setOpen(false)
										}}
										className="p-[8px] hover:bg-uom-blue-light-25"
									>
										<span className="sr-only">Close panel</span>
										<MdClose
											className="size-[16px]"
											aria-hidden="true"
										/>
									</button>
								</div>
								<div className="shrink-0 border-b border-uom-grey-light-50 p-[24px]">
									<h1 className="mt-[8px] font-lead text-[30px] font-[500] leading-[110%] text-uom-heritage-100">Revisions</h1>
									<p className="mt-[8px] text-[18px] leading-[140%]">Review and copy earlier versions of prompt enhancements to revert to previous configurations if needed.</p>
								</div>
								<div className="p-[24px] between:mt-[12px]">
									{revisions == null || revisions.length == 0 ? (
										<div className="mt-[12px] flex items-center gap-[10px] bg-uom-blue-light-25 p-[12px]">
											<div className="shrink-0 bg-uom-blue-light-50 p-[6px]">
												<MdInfo className="size-[20px]" />
											</div>
											<div className="text-uom-heritage-100">No revisions available</div>
										</div>
									) : (
										revisions.map((revision, index) => {
											return (
												<Disclosure
													key={index}
													as="div"
													className="group flex min-w-min flex-col"
												>
													<div className="flex w-full flex-shrink-0 items-center justify-between gap-x-[12px] border border-uom-grey-dark-25 px-[12px] py-[14px] group-data-[open]:bg-uom-grey-light-25">
														<DisclosureButton className="text text-start text-[14px] font-[600] text-uom-heritage-100">{convertIsoDateToRevisionsFormat(revision.modified_datetime)}</DisclosureButton>

														<div className="flex shrink-0 items-center gap-x-[48px]">
															{userFullNames && (
																<div className="rounded-[2px] bg-uom-blue-light-25 px-[10px] py-[4px] text-[12px] font-[600] leading-[100%] text-uom-heritage-100">
																	Updated by {userFullNames[revision.user]}
																</div>
															)}

															<DisclosureButton className="group flex items-center justify-center gap-2 border border-uom-heritage-100 p-[3px] hover:bg-uom-blue-light-25">
																<ChevronDownIcon className="size-[24px] group-data-[open]:rotate-180" />
															</DisclosureButton>
														</div>
													</div>
													<DisclosurePanel className="h-full w-full flex-shrink-0 border border-t-0 border-uom-grey-dark-25 bg-uom-grey-light-25 p-[12px]">
														{revision.changed_prompts.default.change.previous_prompt.length > 0 ? (
															<>
																<div className="h-full max-h-[300px] w-full overflow-y-auto border border-uom-grey-dark-50 bg-white p-[24px] text-[18px] leading-[140%]">
																	{revision.changed_prompts.default.change.previous_prompt}
																</div>
																<div className="flex items-center justify-end">
																	<CopyButton text={revision.changed_prompts.default.change.previous_prompt} />
																</div>
															</>
														) : (
															<div className="text-[18px]">Prompt enhancement was reset to default</div>
														)}
													</DisclosurePanel>
												</Disclosure>
											)
										})
									)}
								</div>
							</div>
						</DialogPanel>
					</div>
				</div>
			</div>
		</Dialog>
	)
}

export const LockOpen = ({className}: {className: string}) => (
	<svg
		className={className}
		viewBox="0 0 16 16"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			id="Vector"
			d="M4.0013 5.33317H10.0013V3.99984C10.0013 3.44428 9.80686 2.97206 9.41797 2.58317C9.02908 2.19428 8.55686 1.99984 8.0013 1.99984C7.44575 1.99984 6.97352 2.19428 6.58464 2.58317C6.19575 2.97206 6.0013 3.44428 6.0013 3.99984H4.66797C4.66797 3.07762 4.99297 2.2915 5.64297 1.6415C6.29297 0.991504 7.07908 0.666504 8.0013 0.666504C8.92352 0.666504 9.70964 0.991504 10.3596 1.6415C11.0096 2.2915 11.3346 3.07762 11.3346 3.99984V5.33317H12.0013C12.368 5.33317 12.6819 5.46373 12.943 5.72484C13.2041 5.98595 13.3346 6.29984 13.3346 6.6665V13.3332C13.3346 13.6998 13.2041 14.0137 12.943 14.2748C12.6819 14.5359 12.368 14.6665 12.0013 14.6665H4.0013C3.63464 14.6665 3.32075 14.5359 3.05964 14.2748C2.79852 14.0137 2.66797 13.6998 2.66797 13.3332V6.6665C2.66797 6.29984 2.79852 5.98595 3.05964 5.72484C3.32075 5.46373 3.63464 5.33317 4.0013 5.33317ZM8.0013 11.3332C8.36797 11.3332 8.68186 11.2026 8.94297 10.9415C9.20408 10.6804 9.33464 10.3665 9.33464 9.99984C9.33464 9.63317 9.20408 9.31928 8.94297 9.05817C8.68186 8.79706 8.36797 8.6665 8.0013 8.6665C7.63464 8.6665 7.32075 8.79706 7.05964 9.05817C6.79852 9.31928 6.66797 9.63317 6.66797 9.99984C6.66797 10.3665 6.79852 10.6804 7.05964 10.9415C7.32075 11.2026 7.63464 11.3332 8.0013 11.3332Z"
			fill="currentColor"
		/>
	</svg>
)

import {Dialog, DialogPanel, DialogTitle, Transition, TransitionChild} from '@headlessui/react'
import {Fragment, type PropsWithChildren, useRef} from 'react'

interface Props {
	title: string
	open: boolean
	onClose: () => void
}

export const ErrorModal = ({open, onClose, title, children}: PropsWithChildren<Props>) => {
	const cancelButtonRef = useRef(null)

	return (
		<Transition
			show={open}
			as={Fragment}
			appear={true}
		>
			<Dialog
				as="div"
				className="relative z-10"
				initialFocus={cancelButtonRef}
				onClose={onClose}
			>
				<TransitionChild
					as={Fragment}
					enter="ease-out duration-300"
					enterFrom="opacity-0"
					enterTo="opacity-100"
					leave="ease-in duration-100"
					leaveFrom="opacity-100"
					leaveTo="opacity-0"
				>
					<div className="fixed inset-0 bg-black bg-opacity-75 transition-opacity" />
				</TransitionChild>

				<div className="fixed inset-0 z-10 w-screen overflow-y-auto">
					<div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
						<TransitionChild
							as={Fragment}
							enter="ease-out duration-300"
							enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
							enterTo="opacity-100 translate-y-0 sm:scale-100"
							leave="ease-in duration-100"
							leaveFrom="opacity-100 translate-y-0 sm:scale-100"
							leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
						>
							<DialogPanel className="relative w-full max-w-[500px] transform overflow-hidden bg-white text-left transition-all">
								<div className="flex flex-col p-[24px]">
									<DialogTitle className="font-lead text-[24px] leading-[120%] text-uom-heritage-100">{title}</DialogTitle>
									<div className="mt-[8px] text-[18px] leading-[140%]">{children}</div>
								</div>

								<div className="flex items-center justify-end gap-x-[8px] bg-uom-grey-light-25 px-[12px] py-[8px] text-[14px] font-[700] text-uom-heritage-100">
									<button
										type="button"
										className="h-[32px] border border-uom-blue-light-100 bg-uom-blue-light-100 px-[12px] hover:border-uom-heritage-100 hover:bg-uom-blue-light-25 active:border-uom-heritage-100 active:bg-uom-blue-light-75"
										onClick={onClose}
										ref={cancelButtonRef}
									>
										Okay
									</button>
								</div>
							</DialogPanel>
						</TransitionChild>
					</div>
				</div>
			</Dialog>
		</Transition>
	)
}

export const ImpactIcon = ({className}: {className: string}) => (
	<svg
		className={className}
		viewBox="0 0 16 16"
		xmlns="http://www.w3.org/2000/svg"
	>
		<g fill="currentColor">
			<path d="M9.145 0H6.852l1.147 3.944L9.145 0ZM6.852 15.998h2.293L8 12.055l-1.147 3.943ZM3.883 1.047 6.45 4.25 6.001.168l-2.118.879ZM10.004 15.827l2.119-.877-2.568-3.204.45 4.081ZM1.531 3.153l3.6 1.977-1.978-3.599-1.622 1.622ZM12.847 14.466l1.62-1.62-3.6-1.979 1.98 3.6ZM.172 5.997l4.083.45L1.05 3.88.17 5.997ZM14.954 12.119l.877-2.118-4.081-.45 3.204 2.568ZM3.944 8 0 6.851v2.293L3.944 8ZM15.998 9.145V6.852l-3.943 1.147 3.943 1.146ZM.172 10l.879 2.119 3.204-2.568L.172 10ZM11.75 6.447l4.08-.45-.876-2.118-3.204 2.568ZM1.531 12.847l1.622 1.62 1.977-3.6-3.599 1.98ZM12.847 1.531l-1.98 3.6 3.6-1.978-1.62-1.622ZM6.001 15.827l.45-4.08-2.568 3.203L6 15.827ZM10.004.168l-.45 4.083 2.569-3.204-2.119-.879Z" />
			<path d="M3.945 8.002a4.056 4.056 0 1 0 8.113 0 4.056 4.056 0 1 0-8.113 0Zm2.036-2.02A2.837 2.837 0 0 1 8 5.147c.762 0 1.48.297 2.019.837.54.54.837 1.257.837 2.019 0 .762-.297 1.48-.837 2.019-.54.54-1.257.837-2.02.837-.761 0-1.48-.297-2.018-.837A2.837 2.837 0 0 1 5.144 8c0-.761.297-1.48.837-2.018Z" />
			<path d="M8.004 9.753a1.753 1.753 0 1 0 0-3.506 1.753 1.753 0 0 0 0 3.506Zm0-2.307a.554.554 0 0 1 0 1.107.554.554 0 0 1 0-1.107Z" />
		</g>
	</svg>
)

import {createFileRoute} from '@tanstack/react-router'
import {useAtom} from 'jotai'
import {useBotConfig} from '~/api.ts'
import {AilaHeading} from '~/components/AilaHeading.tsx'
import {PendingMessage} from '~/components/PendingMessage.tsx'
import {pendingMessageAtom} from '~/state.ts'

export const Route = createFileRoute('/_student-layout/course/$courseId/threads/chat')({
	component: Subject,
})

function Subject() {
	const [pendingMessage] = useAtom(pendingMessageAtom)
	const botConfig = useBotConfig()
	return (
		<>
			<AilaHeading />
			<div className="mt-[12px] pb-[24px] text-[18px] leading-[140%]">
				Ask me anything about <span className="font-[600]">{botConfig?.tutor_bot_course_name.split('(')[0]?.trim()}</span> ({botConfig?.sis_course_id})
			</div>
			{pendingMessage != null && <PendingMessage />}
		</>
	)
}

export interface BotConfig {
	id: number
	dataset_id: string
	project_id?: string
	sis_course_id: string
	canvas_course_id: number
	status: AllBotStatus
	enabled: boolean
	created_at: string
	locked: boolean
	locked_at?: string
	locked_by_user_name?: string
	tutor_bot_course_name: string
	is_locked_by_current_user: boolean
	autoUnlockAtMs?: number
}

export interface BotConfigUpdate {
	locked?: boolean
	enabled?: boolean
}

// Define the arrays of statuses
export const continuePollingStatuses = ['Created', 'Reset', 'Uploading', 'Processing'] as const
export const stopPollingStatuses = ['Initialised', 'Reset', 'Error', 'Ready'] as const

// Derive types from the const arrays
export type ContinuePollingStatus = (typeof continuePollingStatuses)[number]
export type StopPollingStatus = (typeof stopPollingStatuses)[number]

// Combined type of all statuses
export type AllBotStatus = ContinuePollingStatus | StopPollingStatus

// Type guard function
export function isContinuePollingStatus(status?: AllBotStatus): status is ContinuePollingStatus {
	if (status == null) return false
	return continuePollingStatuses.includes(status as ContinuePollingStatus)
}

export interface BotStatus {
	sis_course_id: string
	id: number
	status: AllBotStatus
}

export interface Module {
	id: string
	name: string
	published: boolean
	items: ModuleItem[]
	numberOfChildPages?: number
	numberOfChildFiles?: number
}

export interface ModuleItem {
	id: string
	name: string
	type: string
	url: string
	published: boolean
	parent_id: string
	size: number
	status?: 'PROCESSING' | 'READY' | 'ERROR'
	comment?: string
	children?: ModuleItem[]
}

export interface Context {
	context: {
		id: number
		context_id: string
		client_id: string
		user_name: string
		given_name: string
		family_name: string
		raw_context_attributes?: string
		rawContextAttributes?: RawContextAttributes
		canvas_course_id: number
		canvas_sis_course_id: string
		canvas_course_name: string
		canvas_user_id: number
		canvas_sis_user_id: string
		canvas_login_id: string
		canvas_user_email_id: string
	}
	enabled: boolean
	authorities: unknown[]
	username: string
	password: string
	account_non_expired: boolean
	account_non_locked: boolean
	credentials_non_expired: boolean
}

export interface Jwt {
	header: {
		alg: string
	}
	payload: {
		canvasBaseUrl: string
		s: string
		role: 'staff' | 'staff_read_only' | 'student'
		contextId: string
		canvasExternalToolId: string
		canvasCourseId: number
		canvasSisCourseId: string
		sub: string
		iat: number
		exp: number
	}
}

export interface RawContextAttributes {
	'https://purl.imsglobal.org/spec/lti/claim/lti11_legacy_user_id': string
	sub: string
	'https://purl.imsglobal.org/spec/lti/claim/deployment_id': string
	'https://purl.imsglobal.org/spec/lti/claim/version': string
	'https://purl.imsglobal.org/spec/lti/claim/lti1p1': {
		user_id: string
		validation_context: unknown
		errors: {
			errors: unknown
		}
	}
	iss: string
	locale: string
	'https://purl.imsglobal.org/spec/lti/claim/tool_platform': {
		validation_context: unknown
		name: string
		guid: string
		product_family_code: string
		version: string
		errors: {
			errors: unknown
		}
	}
	'https://purl.imsglobal.org/spec/lti/claim/custom': {
		canvas_course_id: string
		canvas_adminable_accounts: string
		canvas_account_id: string
		canvas_is_root_admin: boolean
		canvas_user_login_id: string
		canvas_user_sis_id: string
		canvas_assignment_id: string
		canvas_css_common: string
		canvas_account_sis_id: string
		canvas_user_id: string
		canvas_sis_course_id: string
		com_instructure_brand_config_json_url: string
	}
	'https://www.instructure.com/placement': string
	azp: string
	'https://purl.imsglobal.org/spec/lti/claim/lis': {
		course_offering_sourcedid: string
		validation_context: unknown
		person_sourcedid: string
		errors: {
			errors: unknown
		}
	}
	'https://purl.imsglobal.org/spec/lti/claim/launch_presentation': {
		document_target: string
		validation_context: unknown
		width: number
		return_url: string
		locale: string
		errors: {
			errors: unknown
		}
		height: number
	}
	exp: unknown
	iat: unknown
	email: string
	given_name: string
	'https://purl.imsglobal.org/spec/lti/claim/roles': string[]
	nonce: string
	'https://purl.imsglobal.org/spec/lti/claim/resource_link': {
		validation_context: unknown
		description: unknown
		id: string
		title: string
		errors: {
			errors: unknown
		}
	}
	'https://purl.imsglobal.org/spec/lti/claim/target_link_uri': string
	picture: string
	'https://purl.imsglobal.org/spec/lti/claim/context': {
		validation_context: unknown
		id: string
		label: string
		title: string
		type: string[]
		errors: {
			errors: unknown
		}
	}
	aud: string[]
	'https://purl.imsglobal.org/spec/lti/claim/message_type': string
	name: string
	family_name: string
	errors: {
		errors: unknown
	}
}

// Old

export interface ThreadConfig {
	llm_configuration?: LlmConfiguration
	query_configuration?: QueryConfiguration
	prompt_templates?: {
		default: {
			prompt_ai_to_know: string
		}
	}
}

export interface NewProject extends ThreadConfig {
	name: string
	dataset?: string
	description: string
}

export interface Project extends NewProject {
	functions?: unknown[]
	status: string
	SK: string
	PK: string
	modified_datetime: string
	Type: string
	prompt_templates: {
		SOCRATIC: {
			prompt_ai_to_know: string
		}
		default: {
			prompt_ai_to_know: string
		}
		TOPIC_CREATION: {
			prompt_ai_to_know: string
		}
	}
}

export interface LlmConfiguration {
	temperature: number
	model_name: string
}

export interface QueryConfiguration {
	k: number
	size: number
}

export interface DataSet {
	status: string
	scope: string
	modified_datetime: string
	query_configuration: QueryConfiguration
	Type: string
	name: string
	data_classification: string
	data_source_configuration: {
		S3: S3
	}
	vector_configuration: {
		index_build: number
		chunk_size: number | null
		distance_metric: string
		index_name: string
		chunk_type: string
		dimensions: number
	}
	SK: string
	owner: string
	description: string
	PK: string
	comment?: string
}

export interface S3 {
	bucket: string
	max_size_mb: number
	folder: string
}

export interface Thread {
	SK: string
	thread_name: string
	thread_modified_datetime: Date
	project: string
	ui_messages: UIMessage[]
	Type: string
	user: string
	prompt_template_id: string
}

export interface UIMessage extends ThreadConfig {
	role: string
	content: string
	converted_content?: string[]
	language?: string
	id?: string
	response_element?: string
	tokens?: {
		prompt_tokens: number
		completion_tokens: number
		total_tokens: number
	}
	sources?: Source[]
}

export interface Source {
	title: string
	source: string
	uri: string
}

export interface ThreadSummery {
	SK: string
	user: string
	thread_modified_datetime: string
	PK: string
	thread_name: string
	prompt_template_id: string
}

export interface DataSourcesResponse {
	datasources: DataSource[]
	dataset_status: 'READY' | 'PROCESSING' | 'ERROR'
	dataset_comment: string
}

export interface DataSource {
	S3: {
		bucket: string
		key: string
	}
	chunk_size: number
	total_tokens: number
	status: 'READY' | 'PROCESSING' | 'ERROR'
	file_type: string
	uri: string
	source: string
	modified_datetime: string
	total_chunks: number
	Type: string
	prompt_tokens: number
	unique_tag?: string
	file_size: number
	comment: string
	SK: string
	chunk_type: string
	file_name: string
	PK: string
	tokens: number
	title: string
}

export interface ProjectChangelogEntry {
	modified_datetime: string
	changed_prompts: {
		SOCRATIC: {
			change: {
				previous_prompt: string
			}
		}
		default: {
			change: {
				previous_prompt: string
			}
		}
	}
	user: string
	project_id: string
}

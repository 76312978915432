export const BranchIcon = ({className}: {className: string}) => (
	<svg
		className={className}
		viewBox="0 0 20 20"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M11.85 10.6987L13.0333 9.51536C13.6 10.0904 14.15 10.832 14.525 11.9654L12.9083 12.3737C12.6417 11.632 12.2667 11.1237 11.85 10.6987ZM10.8333 14.9987L14.1667 18.332L17.5 14.9987H14.9833C14.9667 14.3237 14.9167 13.7154 14.825 13.1904L13.2083 13.5987C13.2667 13.9987 13.3083 14.4737 13.3167 14.9987H10.8333ZM2.5 14.9987L5.83333 18.332L9.16667 14.9987L6.675 14.9987C6.75833 11.932 7.74167 11.0404 8.79167 10.0987C9.20833 9.73203 9.63333 9.33203 10 8.80703C10.2833 9.21536 10.6083 9.54037 10.9417 9.84037L12.1167 8.66536C11.3417 7.95703 10.8333 7.38203 10.8333 5.83203V1.66537H9.16667V5.83203C9.16667 7.51536 8.575 8.0487 7.675 8.85703C6.525 9.89036 5.10833 11.1737 5.00833 14.9987H2.5Z"
			fill="currentColor"
		/>
	</svg>
)
